<template>
  <div v-if="!moduleIsReady" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
  <router-view v-if="moduleIsReady" />
</template>

<script>
export default {
  created() {
    this.getData();
  },
  mounted() {},
  computed: {
    ticketList() {
      return this.$store.getters["ticket/getTicketList"];
      /*return true;*/
    },

    moduleIsReady() {
      return this.ticketList;
      /*return true;*/
    },
  },
  methods: {
    async getData() {
      await this.$store.dispatch("ticket/tickets");
      console.log("getters :", this.$store.getters["ticket/getTicketList"]);
    },
  },
};
</script>
